<template>
  <v-card elevation="0" class="chart-card">
    <v-card-title class="title-card-chart">Resultado mensal em R$</v-card-title>
    <v-card-text>
      <Chart :chartOptions="chartOptions" />
    </v-card-text>
  </v-card>
</template>

<script>
import campanha from "@/services/http/campanhaService";

export default {
  props: {
    categories: {},
    series: {},
    query: {}
  },
  components: {
    // Chart
    Chart: () => import("@/components/shared/BaseChartTeste")
  },
  data() {
    return {
      enterprise: 0,
      years: {},
      dataDashboard: { name: "Resultado", data: [] },
      totalBonus: 0,
      chartOptions: {
        chart: {
          // type: "spline", linhas curvadas
          type: "line",
          // type: "areaspline", linhas curvadas com background
          backgroundColor: "rgba(0,0,0,0)",
          height: 300
        },
        // linear-gradient(90deg, rgba(10,32,58,1) 0%, rgba(42,87,135,1) 100%)
        colors: ["#D46D26", "#e25ced", "#4caf50"],
        title: {
          text: ""
        },
        legend: {
          itemStyle: {
            color: "rgba(255,255,255, 0.3)"
          }
        },
        xAxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
          ],
          labels: {
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          }
        },
        yAxis: {
          title: {
            text: "Valor Bônus",
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          },
          gridLineColor: "rgba(255,255,255, 0.1)",
          labels: {
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          }
        },
        // tooltip: {
        //   headerFormat:
        //     '<span style="font-size:10px">{point.key}</span><table>',
        //   pointFormat:
        //     '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //     '<td style="padding:0"><b>R$ {point.y},00</b></td></tr>',
        //   footerFormat: "</table>",
        //   shared: true,
        //   useHTML: true
        // },
        plotOptions: {
          // area: {
          //   fillColor: {
          //     linearGradient: [0, 0, 0, 300],
          //     stops: [
          //       [0, "rgba(255,255,255, 0.5)"],
          //       [1, "rgba(0,0,0,0)"]
          //     ]
          //   }
          // },
          line: {
            dataLabels: {
              enabled: true,
              color: "white"
            },
            enableMouseTracking: true
          }
          // series: {
          //   fillColor: {
          //     linearGradient: [0, 0, 0, 300],
          //     stops: [
          //       [0, "rgba(255,255,255, 0.5)"],
          //       [1, "rgba(0,0,0,0)"]
          //     ]
          //   }
          // },
          // areaspline: {
          //   fillOpacity: 0.5
          // }
        },
        credits: {
          enabled: false
        },
        series: [],
        lang: {
          noData: "Não há dados para serem apresentados"
        }
      }
    };
  },

  methods: {
    //TO-DO: GRAFICO DINAMICO POR EMPRESA
    //MELHORAR FILTRO DE APURAÇÃO QUE RESETA APOS VOLTAR AO COMPONENT
    async fetchResMonth() {
      this.years = [
        this.query.ano_ref - 2,
        this.query.ano_ref - 1,
        this.query.ano_ref
      ];
      this.chartOptions.series = [];
      for (let years of this.years) {
        if (years > 2020) {
          let month = [];
          let res = [];
          let { data } = await campanha()
            .resultadoRealizadoEmpresa()
            .show({
              id_empresa: this.query.id_empresa,
              per_page: -1,
              ano_ref: years,
              id_band: this.query.id_band
            });
          for (let j = 1; j <= 12; j++) {
            month.push(data.data.filter(item => item.mes_ref == j.toString()));
          }

          month.map((item, k) => {
            let counter = 0;
            item.map(subitem => {
              counter += parseFloat(subitem.resultado) || 0;
            });
            res[k] = counter;
          });

          this.chartOptions.series.push({
            name: years,
            data: res
          });
        }
      }
    }
  },
  watch: {
    query() {
      this.fetchResMonth();
    }
  },
  async created() {
    await this.fetchResMonth();
  }
};
</script>

<style>
.chart-card {
  border-radius: 0px !important;
  background: #141e30;
  background: linear-gradient(90deg, #0c2646 0, #204065 60%, #2a5788);
  padding-bottom: 40px;
}

.title-card-chart {
  color: white;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 20px;
}
</style>
